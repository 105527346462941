/* RESPONSIBLE TEAM: team-product-exploration */
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import type Inbox2Counters from 'embercom/services/inbox2-counters';
import type Session from 'embercom/services/session';
import { InboxCategory } from 'embercom/models/data/inbox/inbox-categories';

export default class InboxWorkspaceController extends Controller {
  queryParams = [];

  @service declare inbox2Counters: Inbox2Counters;
  @service declare session: Session;

  get inboxCount() {
    return (
      this.inbox2Counters.countForInboxWithIdAndCategory(
        this.session.teammate.id.toString(),
        InboxCategory.Admin,
      ) ?? 0
    );
  }
}
