/* RESPONSIBLE TEAM: team-ai-chatbot */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-computed-properties-in-native-classes */

import { inject as service } from '@ember/service';
import { notEmpty, readOnly } from '@ember/object/computed';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { markViewAsSeen } from 'embercom/lib/admin-view-tracking';
import { ANSWERS_INDEX_PAGEVIEW_KEY } from 'embercom/lib/operator/resolution-bot/constants';
import type IndexRoute from 'embercom/routes/apps/app/fin-ai-agent/custom-answers/index';
import type Router from '@ember/routing/router-service';
import { computed } from '@ember/object';
import type Store from '@ember-data/store';

export default class IndexController extends Controller {
  @service declare appService: $TSFixMe;
  @service declare intercomEventService: $TSFixMe;
  @service declare notificationsService: $TSFixMe;
  @service declare router: Router;

  queryParams = [
    'status',
    'language',
    'rangeStart',
    'rangeEnd',
    {
      importType: 'import-type',
      importStatus: 'import-status',
      tagIds: 'tag_ids',
    },
  ];

  range = null;
  status = 'all';
  language = null;
  importType = null;
  importStatus = null;
  tagIds: null | string = null;

  declare model: ModelFromRoute<IndexRoute>;

  @readOnly('appService.app')
  declare app: $TSFixMe;
  @service declare store: Store;

  @notEmpty('clusterData.cluster_ids') declare hasUnansweredQuestionClusters: boolean;

  get product() {
    return this.app.answerBotProduct;
  }
  get clusterData() {
    return this.model.clusterData;
  }

  get answers() {
    return this.model.answers;
  }

  get liveResolutionBotBehaviors() {
    return this.model.liveResolutionBotBehaviors;
  }

  @computed('tagIds')
  get selectedTagIds() {
    return this.tagIds ? this.tagIds.split(',') : [];
  }

  @computed('model.conversationImports')
  get hasActiveZendeskChatConversationImport() {
    return this.model.conversationImports.any(
      (conversationImport: $TSFixMe) =>
        conversationImport.active && conversationImport.origin === 'zendesk_chat',
    );
  }

  @computed('model.answers.length', 'app.canUseFinExperience')
  get shouldShowMoreAnswersBanner() {
    let displayableAnswers = this.model.answers
      .filterBy('fromTemplate', false)
      .filterBy('hasContent', true);
    return (
      !this.app.canUseFinExperience &&
      displayableAnswers.length >= 1 &&
      displayableAnswers.length <= 10
    );
  }

  @computed(
    'model.aiAgentSettings.{contentSuggestions,contentSuggestionsEnabledForUsers,contentSuggestionsEnabledForVisitors,predictiveAnswersEnabledFor,predictiveAnswersEnabledForUsers,predictiveAnswersEnabledForVisitors}',
  )
  get shouldShowAutoSuggestBanner() {
    return (
      (!this.model.aiAgentSettings.contentSuggestionsEnabledForUsers &&
        !this.model.aiAgentSettings.contentSuggestionsEnabledForVisitors) ||
      (!this.model.aiAgentSettings.predictiveAnswersEnabledForUsers &&
        !this.model.aiAgentSettings.predictiveAnswersEnabledForVisitors)
    );
  }

  loadingAnimationIsActive = false;

  @action
  createAnswer() {
    this.router.transitionTo(`${this.app.answersRoute}.new`, {
      queryParams: {
        language: this.language,
      },
    });
  }

  @action
  completeLoadingAnimation() {
    markViewAsSeen(this.store, ANSWERS_INDEX_PAGEVIEW_KEY);
    this.set('loadingAnimationIsActive', false);
  }

  @action
  filterByLanguage(language: string) {
    this.router.transitionTo({ queryParams: { language } });
  }

  @action
  filterByStatus(status: string) {
    this.router.transitionTo({ queryParams: { status } });
  }

  @action
  filterByTagIds(tagIds: string) {
    this.router.transitionTo({ queryParams: { tagIds } });
  }

  @action
  filterByDateRange(rangeStart: string, rangeEnd: string) {
    this.router.transitionTo({
      queryParams: {
        rangeStart,
        rangeEnd,
      },
    });
  }
}
